// TODO: move functionality to ./router.ts
import routes from './routes'
import { validContentTypes } from 'common/src/types'

export const match = (location: string, props: any) => {
  const route = routes.find((r) => {
    const sub = (s: string) => s.split('/')[1] || ''
    return sub(location) === sub(r.path)
  })
  const componentId = componentIdFromRouteId(route?.id)
  const preserve = route?.preserve || false
  const component = preserve ? null : props[componentId]

  return {
    componentId,
    component,
  }
}

export const componentIdFromRouteId = (routeId: string | undefined) => {
  let componentId = routeId || 'root'
  if (validContentTypes.includes(componentId as any)) {
    componentId = 'editor-shell'
  }
  return componentId
}
